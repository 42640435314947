import { Image } from 'nordic/image';
import { Title, Text } from '@andes/typography';

import Footer from '@app/components/footer';

export const View = ({ translations }) => {
  return (
    <>
      <div className="not-found-page">
        <Image src="not-found-illustration.png" alt="Not found illustration" />
        <div>
          <Title className="andes-title--not-found" component="h1">
            {translations.title}
          </Title>
          <Text
            className="andes-text--not-found"
            component="p"
            color="secondary"
            size="m"
          >
            {translations.text}
          </Text>
        </div>
      </div>
      <div className="footer-not-found">
        <Footer />
      </div>
    </>
  );
};
