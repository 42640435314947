import { FC } from 'react';

import { Image } from 'nordic/image';

import { Anchor } from '@linkinbio-web-components/icons';

import { FooterProps } from './types';

const Footer: FC<FooterProps> = ({
  namespace = 'footer',
  textSRL = 'Crear gratis mi Bio libre',
  urlImage = 'https://http2.mlstatic.com/storage/mshops-appearance-api/resources/linkinbio/images/logoMeli.png',
  footerUrl = 'https://www.mercadolibre.com.ar/stop/biolibre',
}) => (
  <footer className={`${namespace}__container lib-footer-border-color`}>
    <Image src={urlImage} width="90" height="23" alt="logo" />
    <h2 className="footer-link lib-footer-text-color">
      <Anchor />

      <a
        className="lib-footer-text-color"
        title={textSRL}
        target="blank"
        href={footerUrl}
      >
        {textSRL}
      </a>
    </h2>
  </footer>
);

export default Footer;
